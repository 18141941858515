import React, { useEffect, useState } from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import axios from 'axios';
import { PdfContainer } from './pdfDisplay';
import { ChatContainer } from './chatSection';
import { ErrorType } from '../constants/errorType';
import { useNavigate, useParams } from 'react-router';
import { getUrl } from '../utils';
import { useCallback } from 'react';
import { AuthStatus, RouterPath } from '../constants';
import { NotificationType, notify } from './reusableComponents';

const logout = (vendorId, setIsGenerating, setAuthState, navigate) => {
    notify('Login timeout! Please login again.', NotificationType.INFO);
    setIsGenerating(false);

    // Get productSupport data from localStorage
    const productSupport = JSON.parse(localStorage.getItem('productSupport'));
    const foundVendor = productSupport[vendorId];
    if (foundVendor) {
        productSupport[vendorId] = {
            ...foundVendor,
            token: null,
        };

        localStorage.setItem('productSupport', JSON.stringify(productSupport));
    }

    setAuthState(AuthStatus.NOT_AUTHENTICATED);
    navigate(`/${RouterPath.VERIFY_CAPTCHA}/${vendorId}`);
};

const ChatAndPdfContainer = (props) => {
    const { vendorId } = useParams(); // Extract the vendorId from the URL path
    const navigate = useNavigate();

    const { selectedProductCategory, selectedProduct, isSmallScreen } = props;

    const [query, setQuery] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [currentqna, setcurrentqna] = useState([]);

    const [questionsData, setQuestionsData] = useState(new Map());
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [abortController, setAbortController] = useState(null);

    const handleSubmit = useCallback(async () => {
        try {
            const isAuthenticated = props.verifyAuthentication(vendorId);
            if (!isAuthenticated) {
                return;
            }

            if (query === '') {
                notify('Question cannot be empty', NotificationType.INFO);
                return;
            }

            //e.preventDefault();
            setIsGenerating(true);

            // Create a new AbortController
            const controller = new AbortController();
            setAbortController(controller); // Save it in state

            const productCategory = selectedProductCategory;
            const product = selectedProduct;
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/run-customer-qna`
            );

            const response = await axios.post(
                dynamicUrl,
                {
                    token: props.user.token,
                    query: query,
                    productCategory: productCategory,
                    product: product,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    signal: controller.signal, // Pass the signal
                }
            );

            const data = response.data;

            if (data) {
                const questionData = {
                    qid: data.qid,
                    question: data.question,
                    answer: data.answer,
                    timestamp: new Date().toLocaleString(),
                    fileToChat: data.metadata[0].pdf_name,
                    chunksData: [],
                    queries: [],
                    productCategory: data.productCategory,
                    product: data.product,
                };

                data.chunksData.forEach((chunk) => {
                    const chunkData = {
                        folderName: chunk.metadata.foldername,
                        fileName: chunk.metadata.source,
                        actualFileName: chunk.metadata.source,
                        pageNumber: chunk.metadata.page_number,
                        pageContent: chunk.page_content,
                    };

                    questionData.chunksData.push(chunkData);
                });

                questionsData.set(data.qid, questionData);

                setQuestionsData(questionsData);
                setActiveQuestion(questionData);

                const qna = {
                    question: data.question,
                    answer: data.answer,
                    qid: data.qid,
                    productCategory: data.productCategory,
                    product: data.product,
                };
                setcurrentqna([qna]);

                setQuery('');
            } else if (data.error || data.detail) {
                notify(
                    `Error: ${data.error || data.detail}`,
                    NotificationType.ERROR
                );
            }
        } catch (error) {
            if (error.name === ErrorType.ABORT_ERROR) {
                notify(
                    'Request changed due to page change while processing',
                    NotificationType.INFO
                );
            } else {
                console.log('Error:', error);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error &&
                    error.response.data.error === 'invalid token'
                ) {
                    logout(
                        vendorId,
                        setIsGenerating,
                        props.setAuthState,
                        navigate
                    );
                } else {
                    notify(
                        'Something went wrong while generating answer',
                        NotificationType.ERROR
                    );
                }
            }
        } finally {
            setIsGenerating(false);
        }
    }, [
        currentqna,
        props,
        query,
        questionsData,
        selectedProduct,
        selectedProductCategory,
        vendorId,
    ]);

    // Effect to handle repository change
    useEffect(() => {
        if (abortController) {
            // Abort the previous request
            abortController.abort();
        }
        // Clear the controller after aborting
        setAbortController(null);
    }, []); // Run this effect whenever repo changes

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Reset togglePdfVisibility here
            props.togglePdfVisibility();
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array to ensure this effect runs only once on mount

    useEffect(() => {
        // Reset both states to false when the component mounts or updates
        props.setShowPdf(false);
        props.setOnButton(false);
    }, []);

    return (
        <div className='h-full w-full'>
            <>
                <div className='flex items-center justify-center px-0 z-0 h-[89vh]'>
                    <div className='w-full h-full'>
                        {activeQuestion ? (
                            <ReflexContainer orientation='vertical'>
                                {!props.showPdf && (
                                    <ReflexElement className='left-pane'>
                                        <div className='pane-content pr-0.5 w-full h-full bg-transparent'>
                                            <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                <ChatContainer
                                                    activeQuestion={
                                                        activeQuestion
                                                    }
                                                    isGenerating={isGenerating}
                                                    setIsGenerating={
                                                        setIsGenerating
                                                    }
                                                    questionsData={
                                                        questionsData
                                                    }
                                                    query={query}
                                                    currentQnA={currentqna}
                                                    user={props.user}
                                                    setQuery={setQuery}
                                                    handleSubmit={handleSubmit}
                                                    togglePdfVisibility={
                                                        props.togglePdfVisibility
                                                    }
                                                    selectedProductCategory={
                                                        selectedProductCategory
                                                    }
                                                    selectedProduct={
                                                        selectedProduct
                                                    }
                                                    isSmallScreen={
                                                        isSmallScreen
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ReflexElement>
                                )}

                                {!isSmallScreen && (
                                    <ReflexSplitter
                                        style={{
                                            border: 0,
                                            background: 'transparent',
                                            width: '6px',
                                            zIndex: 0,
                                        }}
                                    />
                                )}

                                {/* Render PDF view based on showPdf */}
                                {(props.showPdf ||
                                    props.onButton ||
                                    !isSmallScreen) && (
                                    <ReflexElement className='right-pane'>
                                        <div className='pane-content pl-0.5 w-full h-full bg-transparent'>
                                            <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                <PdfContainer
                                                    user={props.user}
                                                    activeQuestion={
                                                        activeQuestion
                                                    }
                                                    togglePdfVisibility={
                                                        props.togglePdfVisibility
                                                    }
                                                    isSmallScreen={
                                                        isSmallScreen
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ReflexElement>
                                )}
                            </ReflexContainer>
                        ) : (
                            <ReflexContainer orientation='vertical'>
                                <ReflexElement className='middle-pane'>
                                    <div className='pane-content w-full h-full bg-transparent'>
                                        <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                            <ChatContainer
                                                activeQuestion={activeQuestion}
                                                isGenerating={isGenerating}
                                                setIsGenerating={
                                                    setIsGenerating
                                                }
                                                questionsData={questionsData}
                                                query={query}
                                                currentQnA={currentqna}
                                                user={props.user}
                                                setQuery={setQuery}
                                                handleSubmit={handleSubmit}
                                                togglePdfVisibility={
                                                    props.togglePdfVisibility
                                                }
                                                selectedProductCategory={
                                                    selectedProductCategory
                                                }
                                                selectedProduct={
                                                    selectedProduct
                                                }
                                                isSmallScreen={isSmallScreen}
                                            />
                                        </div>
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

export default ChatAndPdfContainer;
