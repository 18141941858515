import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faFile } from '@fortawesome/free-solid-svg-icons';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { ChatActions } from './chatActions';
import { ChatDisplay } from './chatDisplay';
import { Button } from '../reusableComponents';

export const ChatContainer = ({
    activeQuestion,
    questionsData,
    isGenerating,
    query,
    currentQnA,
    user,
    setQuery,
    handleSubmit,
    togglePdfVisibility,
    selectedProductCategory,
    selectedProduct,
    isSmallScreen,
}) => {
    return (
        <ReflexContainer orientation='horizontal'>
            <ReflexElement
                className='header border-b shadow-sm'
                style={{
                    minHeight: '3.5rem',
                    height: '3.5rem',
                    maxHeight: '3.5rem',
                }}
            >
                <div className='flex pane-content w-full h-full p-2 items-center'>
                    <div className='w-full h-full flex items-center justify-between text-texts-secondary truncate px-2'>
                        <div className='w-fit'>
                            <FontAwesomeIcon
                                icon={faComments}
                                className='mr-2 drop-shadow text-backgrounds-primary200'
                            />
                            <span className='overflow-ellipsis overflow-hidden font-semibold w-full mr-2 text-texts-secondary300'>
                                Chats
                            </span>
                        </div>
                        {isSmallScreen && activeQuestion && (
                            <Button
                                className={'ml-2'}
                                text={'Manual'}
                                hoverText={'View Source'}
                                icon={faFile}
                                onClick={(e) => {
                                    togglePdfVisibility();
                                }}
                                type={'success'}
                            />
                        )}
                    </div>
                </div>
            </ReflexElement>

            <ReflexElement flex={1}>
                <div className='pane-content h-full'>
                    <ChatDisplay
                        activeQuestion={activeQuestion}
                        questionsData={questionsData}
                        setQuery={setQuery}
                        currentQnA={currentQnA}
                        user={user}
                        isGenerating={isGenerating}
                        selectedProductCategory={selectedProductCategory}
                        selectedProduct={selectedProduct}
                        isSmallScreen={isSmallScreen}
                    />
                </div>
            </ReflexElement>

            <ReflexElement
                className='footer border-t shadow'
                style={{
                    minHeight: '4rem',
                    height: '4rem',
                    maxHeight: '4rem',
                }}
            >
                <div className='pane-content w-full h-full'>
                    <div className='flex items-center justify-end w-full h-full px-2'>
                        <ChatActions
                            query={query}
                            currentQnA={currentQnA}
                            setQuery={setQuery}
                            handleSubmit={handleSubmit}
                            isGenerating={isGenerating}
                            selectedProductCategory={selectedProductCategory}
                            selectedProduct={selectedProduct}
                            isSmallScreen={isSmallScreen}
                        />
                    </div>
                </div>
            </ReflexElement>
        </ReflexContainer>
    );
};
