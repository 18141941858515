import React, { useRef } from 'react';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { Button, Input } from '../reusableComponents';

export const ChatActions = ({
    query,
    setQuery,
    handleSubmit,
    isGenerating,
    selectedProductCategory,
    selectedProduct,
    isSmallScreen,
}) => {
    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            buttonRef?.current?.click(); // Programmatically click the button
        }
    };

    return (
        <div className='w-full'>
            <div className='flex items-center justify-between'>
                <div className='w-full bg-backgrounds-white border border-1 rounded-xl'>
                    <Input
                        className='px-4 h-fit w-full'
                        value={query}
                        type='text'
                        placeholder={
                            selectedProductCategory && selectedProduct
                                ? `Ask question here for ${selectedProduct} (${selectedProductCategory})`
                                : `Ask question here after selecting a product`
                        }
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <div className='flex items-center justify-between ml-2'>
                    <Button
                        ref={buttonRef}
                        text={!isSmallScreen ? 'Send' : null}
                        className={`ml-1 mr-1`}
                        hoverText='Send question and get single answer'
                        disabled={query.length === 0 || isGenerating}
                        onClick={async () => {
                            await handleSubmit(false);
                            setQuery('');
                        }}
                        icon={faPaperPlane}
                        type={'success'}
                    />
                </div>
            </div>
        </div>
    );
};
