import React from 'react';

export const Brand = ({
    vendorDetails,
    iconClassName = 'w-16 h-16',
    showCopyRightText = true,
}) => {
    if (vendorDetails) {
        return (
            <a
                href={vendorDetails.domain ? vendorDetails.domain : ''}
                className='flex flex-col w-fit items-center justify-center truncate select-none font-sans text-texts-secondary300 truncate scale-90'
                target='_blank'
                rel='noreferrer'
            >
                <div className='w-full flex justify-center items-center mb-1'>
                    {vendorDetails.logo && (
                        <div
                            className='relative flex items-center justify-center bg-backgrounds-white'
                            style={{ width: '50px', height: '50px' }}
                        >
                            <img
                                className={iconClassName}
                                src={URL.createObjectURL(
                                    new Blob([vendorDetails.logo])
                                )}
                                alt='camera'
                                draggable='false'
                                style={{ width: '45px' }}
                            />
                        </div>
                    )}
                    <div className='max-w-4/6 ml-1 truncate'>
                        <span className='text-xl font-semibold truncate'>
                            {vendorDetails.name}
                        </span>
                    </div>
                </div>

                {showCopyRightText && (
                    <div className='max-w-4/6 text-xs truncate'>
                        {vendorDetails.copyRightText}
                    </div>
                )}
            </a>
        );
    }

    return <></>;
};
