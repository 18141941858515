import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleRight,
    faComments,
    faFilePowerpoint,
    faIcons,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import { PdfViewer } from './pdfViewer';
import { PdfActions } from './pdfActions';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { Button } from '../reusableComponents';
import { getFileFormat, getUrl } from '../../utils';

const scaleSteps = 0.1;
const minScale = 0.5;
const maxScale = 3;

export const PdfContainer = ({
    user,
    activeQuestion,
    togglePdfVisibility,
    isSmallScreen,
}) => {
    const [activeChunkData, setActiveChunkData] = useState(null);
    // const [chunksData, setChunksData] = useState([]);
    const [pdfUrl, setPdfUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [activePageNumber, setActivePageNumber] = useState(0);
    const [activePageContent, setActivePageContent] = useState(null);
    const [totalPageNumbers, setTotalPageNumbers] = useState(0);
    const [scale, setScale] = useState(window.innerWidth < 1024 ? 0.6 : 1);

    useEffect(() => {
        if (
            activeQuestion &&
            activeQuestion.chunksData &&
            activeQuestion.chunksData.length > 0
        ) {
            const { chunksData } = activeQuestion;
            // Sorting the chunks according to page number.
            chunksData.sort(function (chunk, anotherChunk) {
                return chunk.pageNumber - anotherChunk.pageNumber;
            });
            const firstChunkData = chunksData[0];

            setActiveChunkData(firstChunkData);
            // setChunksData(chunksData);
        }
    }, [activeQuestion]);

    useEffect(() => {
        if (activeChunkData) {
            const { folderName, fileName } = activeChunkData;

            const pageNumber = activeChunkData.pageNumber;
            const pageContent = { chunks: [activeChunkData.pageContent] };
            const actualFileName = activeChunkData.actualFileName;
            const fileExtension = getFileFormat(fileName);
            const pdfFileName = fileName.replace(fileExtension, 'pdf');

            const encodedFolderName = encodeURIComponent(folderName);
            const encodedFileName = encodeURIComponent(pdfFileName);

            const pdfFileUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/get-pdf-for-customer/?foldername=${encodedFolderName}&filename=${encodedFileName}&token=${user.token}`
            );

            setPdfUrl(pdfFileUrl);
            setFileName(actualFileName);
            setActivePageNumber(pageNumber);
            setActivePageContent(pageContent);
        }
    }, [activeChunkData]);

    // const handleSourceChange = (index) => {
    //     const newActiveChunk = chunksData[index];
    //     const pageNumber = newActiveChunk.pageNumber;
    //     setActiveChunkData(newActiveChunk);
    //     setActivePageNumber(pageNumber);
    // };

    let productCategory = null;
    let product = null;

    if (Array.isArray(activeQuestion.productCategory)) {
        productCategory = activeQuestion.productCategory[0];
    } else {
        productCategory = activeQuestion.productCategory;
    }

    if (Array.isArray(activeQuestion.product)) {
        product = activeQuestion.product[0];
    } else {
        product = activeQuestion.product;
    }

    return (
        <ReflexContainer orientation='horizontal'>
            <ReflexElement
                className='header border-b'
                style={{
                    minHeight: '3.5rem',
                    height: '3.5rem',
                    maxHeight: '3.5rem',
                }}
            >
                <div className='flex items-center pane-content w-full h-full p-2'>
                    <div className='w-full h-full flex items-center justify-between px-2'>
                        <div className='h-full w-fit flex items-center text-texts-secondary truncate mr-2'>
                            {productCategory && !isSmallScreen && (
                                <div className='w-fit text-texts-secondary300 truncate'>
                                    <div className='flex items-center truncate mr-2'>
                                        <FontAwesomeIcon
                                            icon={faIcons}
                                            className='mr-2 drop-shadow text-backgrounds-primary200'
                                        />
                                        <div className='truncate font-semibold mr-3'>
                                            {productCategory}
                                        </div>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className='drop-shadow mr-2'
                                        />
                                    </div>
                                </div>
                            )}
                            <div className='w-fit text-texts-secondary300 truncate'>
                                <div className='flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={faFilePowerpoint}
                                        className='mr-2 drop-shadow text-backgrounds-primary200'
                                    />
                                    <div className='truncate font-semibold'>
                                        {product ? product : 'Manual'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {window.innerWidth <= 1024 && activeQuestion && (
                            <Button
                                className={'ml-2'}
                                text={'Chats'}
                                hoverText={'View Chat'}
                                icon={faComments}
                                onClick={(e) => {
                                    togglePdfVisibility();
                                }}
                                type={'success'}
                            />
                        )}
                    </div>
                </div>
            </ReflexElement>

            <ReflexElement flex={1}>
                <div className='pane-content'>
                    <PdfViewer
                        pdfFile={pdfUrl}
                        fileName={fileName}
                        highlightTexts={activePageContent}
                        pageNumber={activePageNumber}
                        activeChunkPage={
                            activeChunkData ? activeChunkData.pageNumber : 0
                        }
                        setPageNumber={setActivePageNumber}
                        setTotalPageNumbers={setTotalPageNumbers}
                        scale={scale}
                    />
                </div>
            </ReflexElement>

            <ReflexElement
                className='footer border-t'
                style={{
                    minHeight: '4rem',
                    height: '4rem',
                    maxHeight: '4rem',
                }}
            >
                <div className='pane-content w-full h-full'>
                    <div className='flex items-center justify-between w-full h-full px-4'>
                        <PdfActions
                            pageNumber={activePageNumber}
                            totalPageNumbers={totalPageNumbers}
                            setPageNumber={setActivePageNumber}
                        />

                        {/* <div className='flex justify-center items-center mr-2'>
                            <div className='flex justify-center items-center text-texts-secondary300 truncate'>
                                <FontAwesomeIcon
                                    icon={faFile}
                                    className='mr-2'
                                />
                                <div className='truncate font-semibold'>
                                    Document Source
                                </div>
                            </div>
                            <Pagination
                                activeId={
                                    activeQuestion ? activeQuestion.qid : null
                                }
                                itemsCount={chunksData.length}
                                itemsPerPage={1}
                                handlePageChange={handleSourceChange}
                                previousLabel={
                                    <Button
                                        hoverText={'Go to previous source'}
                                        icon={faCaretLeft}
                                        type={'warning'}
                                    />
                                }
                                nextLabel={
                                    <Button
                                        hoverText={'Go to next source'}
                                        icon={faCaretRight}
                                        type={'warning'}
                                    />
                                }
                            />
                        </div> */}

                        {/* Scale actions */}
                        <div className='flex items-center rounded-full ml-2 border'>
                            <Button
                                icon={faMagnifyingGlassMinus}
                                type={'warning'}
                                onClick={() => {
                                    const newScale = parseFloat(
                                        parseFloat(scale) - scaleSteps
                                    ).toFixed(1);
                                    setScale(newScale);
                                }}
                                hoverText={
                                    'Decrease the viewing scale of document'
                                }
                                disabled={scale <= minScale}
                            />
                            <div className='w-6 flex justify-center text-sm mx-1 text-texts-secondary300 font-semibold text-nowrap font-semibold'>
                                {scale}x
                            </div>
                            <Button
                                icon={faMagnifyingGlassPlus}
                                type={'warning'}
                                onClick={() => {
                                    const newScale = parseFloat(
                                        parseFloat(scale) + scaleSteps
                                    ).toFixed(1);
                                    setScale(newScale);
                                }}
                                hoverText={
                                    'Increase the viewing scale of document'
                                }
                                disabled={scale >= maxScale}
                            />
                        </div>
                    </div>
                </div>
            </ReflexElement>
        </ReflexContainer>
    );
};
