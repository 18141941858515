import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { Brand } from './brand';
import { Button } from './reusableComponents';
import { DropDownContainer } from './dropDownContainer';

const Navbar = ({
    vendorDetails,
    productDetailsList,
    selectedProductCategory,
    selectedProduct,
    setSelectedProductCategory,
    setSelectedProduct,
    isLoading,
    isSmallScreen,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className='w-full flex justify-center items-center rounded-xl bg-backgrounds-secondary border h-14 py-0 px-2 shadow-sm select-none'>
            <div className='w-full h-full flex justify-between items-center'>
                <Brand
                    iconClassName={'w-10 h-10'}
                    vendorDetails={vendorDetails}
                    showCopyRightText={false}
                />

                {isSmallScreen ? (
                    <button className='mx-2' onClick={toggleMenu}>
                        <FontAwesomeIcon
                            icon={faBars}
                            className='text-2xl text-backgrounds-primary200'
                        />
                    </button>
                ) : (
                    <DropDownContainer
                        productDetailsList={productDetailsList}
                        selectedProductCategory={selectedProductCategory}
                        selectedProduct={selectedProduct}
                        setSelectedProductCategory={setSelectedProductCategory}
                        setSelectedProduct={setSelectedProduct}
                        isLoading={isLoading}
                        isSmallScreen={isSmallScreen}
                    />
                )}
            </div>

            <div className='lg:hidden relative'>
                {isMenuOpen && (
                    <div
                        className='fixed inset-0 bg-backgrounds-black bg-opacity-75 z-50'
                        onClick={closeMenu}
                    >
                        <div className='fixed top-0 right-0 bottom-0 w-64 bg-backgrounds-white shadow-lg z-50 overflow-y-auto'>
                            <div className='flex flex-row justify-between items-center p-4 bg-backgrounds-primary100 font-semibold text-lg text-texts-secondary shadow'>
                                <div className='uppercase'>Actions</div>
                                <Button
                                    className={`h-8 mr-1 ml-10 px-2 flex-end`}
                                    icon={faClose}
                                    type={'danger'}
                                    onClick={closeMenu}
                                />
                            </div>
                            <div className='pb-2 border-b'>
                                <DropDownContainer
                                    productDetailsList={productDetailsList}
                                    selectedProductCategory={
                                        selectedProductCategory
                                    }
                                    selectedProduct={selectedProduct}
                                    setSelectedProductCategory={
                                        setSelectedProductCategory
                                    }
                                    setSelectedProduct={setSelectedProduct}
                                    isLoading={isLoading}
                                    isSmallScreen={isSmallScreen}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
