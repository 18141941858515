import React, { useState } from 'react';
import { Button } from './button';
import { faExpand, faTable } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'antd';
import { TableModal } from './tableModal';
import { DataType } from '../../constants';
import { camelToTitleCase } from '../../utils';

export const ExpandableTable = ({
    columnData,
    tableData,
    pageSize,
    pagination,
    isSmallScreen,
    expandedTableTitle,
    completeData,
}) => {
    const [displayTableModal, setDisplayTableModal] = useState(false);

    if (
        columnData &&
        tableData &&
        Array.isArray(columnData) &&
        Array.isArray(tableData)
    ) {
        let columns = [];
        let dataSource = [];

        if (tableData.length > pageSize || columnData.length > pageSize) {
            const extraSign = '...';

            const columnIterationSize =
                pageSize < columnData.length ? pageSize : columnData.length;
            for (let i = 0; i < columnIterationSize; i++) {
                const data =
                    i === columnIterationSize - 1 ? extraSign : columnData[i];
                const singleColumnData = {
                    title: camelToTitleCase(data),
                    dataIndex: data,
                    key: data,
                };
                columns.push(singleColumnData);
            }

            const rowIterationSize =
                pageSize < tableData.length ? pageSize : tableData.length;
            for (let i = 0; i < rowIterationSize; i++) {
                const dataObj = { key: i };
                for (let index = 0; index < columnIterationSize; index++) {
                    const key =
                        index === columnIterationSize - 1
                            ? extraSign
                            : columnData[index];
                    dataObj[key] =
                        i === rowIterationSize - 1 ||
                        index === columnIterationSize - 1
                            ? extraSign
                            : tableData[i][index];
                }
                dataSource.push(dataObj);
            }
        } else {
            columns = columnData.map((column, index) => {
                return {
                    title: camelToTitleCase(column),
                    dataIndex: column,
                    key: column,
                    sorter: (a, b) => {
                        if (
                            typeof a[column] &&
                            typeof b[column] === DataType.NUMBER
                        ) {
                            return a[column] - b[column];
                        }
                        return a[column]
                            .toString()
                            .localeCompare(b[column].toString());
                    },
                };
            });

            dataSource = tableData.map((data, index) => {
                const dataObj = { key: index };
                columnData.forEach((column, columnIndex) => {
                    dataObj[column] = data[columnIndex];
                });
                return dataObj;
            });
        }

        return (
            <>
                {displayTableModal && (
                    <TableModal
                        title={
                            expandedTableTitle
                                ? expandedTableTitle
                                : 'Table Data'
                        }
                        completeData={completeData}
                        titleIcon={faTable}
                        iconColor={'sky'}
                        onClose={() => setDisplayTableModal(false)}
                        tableColumns={columnData}
                        tableData={tableData}
                        pagination={null}
                        modalClassName={
                            isSmallScreen ? 'w-full' : 'w-fit max-w-full'
                        }
                    />
                )}
                <div className='w-full flex-col'>
                    <div className='w-full flex justify-between items-center break rounded-t-xl mb-1'>
                        <div className='p-1 font-semibold text-texts-secondary300'>
                            <span className='pr-2'>
                                Rows:
                                <span className='ml-1 text-backgrounds-primary400'>
                                    {tableData.length}
                                </span>
                            </span>
                            <span className='px-2'>
                                Columns:
                                <span className='ml-1 text-backgrounds-primary400'>
                                    {columnData.length}
                                </span>
                            </span>
                        </div>
                        <Button
                            type={'success'}
                            icon={faExpand}
                            text={isSmallScreen ? null : 'Expand Table'}
                            hoverText={'Expand to view the full table data'}
                            className=''
                            onClick={() => {
                                setDisplayTableModal(true);
                            }}
                        />
                    </div>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        bordered={true}
                        pagination={pagination}
                        size='small'
                    />
                    {!(
                        tableData.length > pageSize ||
                        columnData.length > pageSize
                    ) && completeData === false ? (
                        <div
                            className={`text-backgrounds-primary300 py-2 ${
                                isSmallScreen ? 'text-sm' : ''
                            }`}
                        >
                            This is partial table data due to data limitation of{' '}
                            {tableData.length} rows per question
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    } else {
        return (
            <div>
                Unable to convert into your answer into correct tabular format,
                please try again!
            </div>
        );
    }
};
