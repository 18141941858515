import pako from 'pako';
import { NotificationType, notify } from '../components/reusableComponents';
// Function to compress and encode the JWT
export const compressAndEncodeJWT = (jwt) => {
    try {
        const compressed = pako.deflate(jwt, { to: 'string' });
        const base64Encoded = btoa(
            String.fromCharCode(...new Uint8Array(compressed))
        );
        return encodeURIComponent(encodeURIComponent(base64Encoded)); // URL-safe encoding
    } catch (error) {
        notify(error.message, NotificationType.ERROR);
    }
};
