import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthStatus, RouterPath } from '../constants';

const VendorWrapper = ({
    authState,
    vendorDetails,
    fetchVendorDetails,
    verifyAuthentication,
}) => {
    const { vendorId } = useParams(); // Extract the vendorId from the URL path
    const navigate = useNavigate();

    useEffect(() => {
        verifyAuthentication(vendorId);

        return () => {};
    }, [vendorId]);

    useEffect(() => {
        if (!vendorId) {
            navigate(`/${RouterPath.NOT_FOUND}`);
            return;
        }

        const path =
            authState === AuthStatus.AUTHENTICATED
                ? `/${RouterPath.CHAT_SUPPORT}/${vendorId}`
                : `/${RouterPath.VERIFY_CAPTCHA}/${vendorId}`;

        if (!vendorDetails) {
            (async () => {
                const isFetched = await fetchVendorDetails(vendorId);
                if (isFetched) {
                    navigate(path);
                } else {
                    navigate(`/${RouterPath.NOT_FOUND}`);
                }
            })();
            return;
        }

        navigate(path);
    }, [authState, vendorDetails, vendorId]);

    // This component doesn't need to render anything, it just fetches the vendor details
    return null;
};

export default VendorWrapper;
