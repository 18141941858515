import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { Brand } from '../brand';
import { Button, Input, NotificationType, notify } from '../reusableComponents';
import { PoweredByInfo } from '../poweredByComponent';
import { faCheck, faIcons } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthStatus, RouterPath } from '../../constants';
import { appVersion } from '../../appInfo';
import axios from 'axios';
import { getUrl } from '../../utils';

const CaptchaFormComponent = ({
    name,
    setName,
    setAuthState,
    vendorDetails,
    vendorId,
    isSmallScreen,
}) => {
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            buttonRef?.current?.click(); // Programmatically click the button
        }
    };

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        try {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha('getAuthToken');
            setToken(token);
        } catch (error) {
            throw new Error(error);
        }
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        (async () => {
            try {
                await handleReCaptchaVerify();
            } catch (error) {
                notify(
                    'Error fetching reCAPTCHA token. Please refresh the page and try again!',
                    NotificationType.ERROR
                );
            }
        })();
    }, [handleReCaptchaVerify]);

    const handleSubmit = async (e) => {
        try {
            if (e) {
                e.preventDefault();
            }

            try {
                await handleReCaptchaVerify();
            } catch (error) {
                console.error('Error during captcha token generation', error);
                throw new Error(
                    'An error occurred during captcha token generation. Please try again.'
                );
            }

            try {
                if (token) {
                    const formData = new FormData();
                    formData.append('token', token);
                    formData.append('username', name);
                    formData.append('user_uuid', vendorId);

                    const dynamicUrl = getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/verify-recaptcha`
                    );

                    // Send the FormData
                    const response = await axios.post(dynamicUrl, formData, {});

                    const jwtToken = response.data.token;

                    const productSupportUserData = JSON.parse(
                        localStorage.getItem('productSupport')
                    );

                    if (productSupportUserData[vendorId]) {
                        productSupportUserData[vendorId] = {
                            ...productSupportUserData[vendorId],
                            id: vendorId,
                            userName: name,
                            token: jwtToken,
                        };
                    } else {
                        productSupportUserData[vendorId] = {
                            id: vendorId,
                            userName: name,
                            token: jwtToken,
                        };
                    }

                    localStorage.setItem(
                        'productSupport',
                        JSON.stringify(productSupportUserData)
                    );

                    setAuthState(AuthStatus.AUTHENTICATED);
                    navigate(`/${RouterPath.CHAT_SUPPORT}/${vendorId}`, {
                        replace: true,
                    });
                }
            } catch (error) {
                console.error('CAPTCHA verification failed', error);
                throw new Error(
                    'CAPTCHA verification failed. Please try again.'
                );
            }
        } catch (error) {
            notify(error.message, NotificationType.ERROR);
        }
    };

    return (
        <div className='w-full flex items-center justify-center min-h-screen bg-gradient-primary select-none p-6'>
            <div className='w-full max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                {vendorDetails && (
                    <div className='w-full flex justify-center items-center mx-auto mb-8'>
                        <Brand
                            iconClassName={'w-10 h-10 mr-0'}
                            vendorDetails={vendorDetails}
                        />
                    </div>
                )}
                <div className='flex flex-col text-sm w-full'>
                    <div className='w-full font-semibold text-lg flex justify-center items-center'>
                        <FontAwesomeIcon
                            icon={faIcons}
                            className='mr-2 text-texts-tertiary400'
                        />
                        <span className='mr-2 text-texts-tertiary400'>
                            Product Support
                        </span>
                        <span className='text-xs truncate text-texts-secondary300'>
                            v{appVersion}
                        </span>
                    </div>
                    <div className='py-8'>
                        <Input
                            className={`w-full rounded-xl border p-3 w-full hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm border`}
                            type={'text'}
                            placeholder='Your Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoFocus={true}
                            onKeyDown={handleKeyDown}
                        />

                        <Button
                            ref={buttonRef}
                            text={'Submit'}
                            className='w-full h-11 mt-8'
                            onClick={async (e) => {
                                await handleSubmit(e);
                            }}
                            icon={faCheck}
                            type={'success'}
                            hoverText={name ? 'Submit' : 'Please add your name'}
                            disabled={!name}
                            active={name}
                        />
                    </div>
                </div>

                <div className='w-full flex justify-center items-center'>
                    <PoweredByInfo />
                </div>
            </div>
        </div>
    );
};

const CaptchaComponent = ({
    name,
    setName,
    setAuthState,
    vendorDetails,
    fetchVendorDetails,
    verifyAuthentication,
    isSmallScreen,
}) => {
    const { vendorId } = useParams(); // Extract the vendorId from the URL path
    const navigate = useNavigate();
    const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

    useEffect(() => {
        verifyAuthentication(vendorId);
        return () => {};
    }, [vendorId]);

    useEffect(() => {
        if (!vendorId) {
            navigate(`/${RouterPath.NOT_FOUND}`);
            return;
        }

        if (!vendorDetails) {
            (async () => {
                const isFetched = await fetchVendorDetails(vendorId);
                if (!isFetched) {
                    navigate(`/${RouterPath.NOT_FOUND}`);
                }
            })();
            return;
        }
    }, [vendorDetails, vendorId]);

    if (!captchaKey) {
        notify('Captcha authentication failed!', NotificationType.ERROR);
        return <></>;
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
            <CaptchaFormComponent
                name={name}
                setName={setName}
                setAuthState={setAuthState}
                vendorDetails={vendorDetails}
                vendorId={vendorId}
                isSmallScreen={isSmallScreen}
            />
        </GoogleReCaptchaProvider>
    );
};

export default CaptchaComponent;
