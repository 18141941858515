import React from 'react';
import { faClose, faHeader } from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Modal = ({
    title,
    titleIcon,
    onClose,
    children,
    className,
    onScroll,
}) => {
    return (
        <div className='fixed top-0 left-0 min-h-screen w-screen flex justify-center items-center bg-backgrounds-black bg-opacity-20 z-50 border backdrop-filter backdrop-blur-sm overflow-auto'>
            <div
                className={`relative bg-backgrounds-white p-6 pt-4 rounded-xl flex flex-col items-center shadow-lg max-w-full max-h-[97vh] ${className}`}
            >
                <Button
                    icon={faClose}
                    type={'danger'}
                    title='Close'
                    onClick={() => onClose()}
                    className={'absolute top-4 right-5'}
                />

                <div className='w-full mb-3 text-texts-secondary300'>
                    <div className='flex items-center font-semibold text-lg'>
                        <FontAwesomeIcon
                            icon={titleIcon ? titleIcon : faHeader}
                            className={`mr-2 text-texts-tertiary`}
                        />
                        <div className='flex-1 overflow-hidden text-ellipsis whitespace-nowrap'>
                            {title ? title : 'Modal'}
                        </div>
                    </div>
                </div>

                <div
                    className='w-full max-h-[80vh] bg-backgrounds-slate border rounded-xl overflow-auto'
                    onScroll={onScroll}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
