import React from 'react';
import { CompanyDetails } from './brand';

export const PoweredByInfo = () => {
    return (
        <div className=' flex items-center text-xs text-texts-secondary300 truncate'>
            <span>Powered by</span>
            <span className='ml-1 truncate'>{CompanyDetails.POWERED_BY}</span>
        </div>
    );
};
