import React, { useEffect, useState } from 'react';
import { Dropdown } from './reusableComponents';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { faIcons } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const buildCategoryListAndMap = (
    productDetailsList,
    selectedProductCategory,
    setSelectedProductCategory,
    setSelectedProduct,
    setProductDetailsMap,
    setProductCategoryList
) => {
    const productCategories = [];
    const productDetailsMapData = new Map();
    if (productDetailsList && productDetailsList.length) {
        productDetailsList.forEach((productDetail, index) => {
            productDetailsMapData.set(
                productDetail.productCategory,
                productDetail.products
            );

            const productCategoryData = {
                key: index,
                label: productDetail.productCategory,
                icon: productDetail.icon,
                iconColor: productDetail.icon,
                selected:
                    productDetail.productCategory === selectedProductCategory,
                action: () => {
                    setSelectedProductCategory(productDetail.productCategory);
                    setSelectedProduct(
                        productDetail.products.length
                            ? productDetail.products[0]
                            : null
                    );
                },
            };
            productCategories.push(productCategoryData);
        });
    }

    setProductDetailsMap(productDetailsMapData);
    setProductCategoryList(productCategories);
};

export const DropDownContainer = ({
    productDetailsList,
    selectedProductCategory,
    selectedProduct,
    setSelectedProductCategory,
    setSelectedProduct,
    isLoading,
    isSmallScreen,
}) => {
    const [productDetailsMap, setProductDetailsMap] = useState(new Map());
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        buildCategoryListAndMap(
            productDetailsList,
            selectedProductCategory,
            setSelectedProductCategory,
            setSelectedProduct,
            setProductDetailsMap,
            setProductCategoryList
        );
    }, [
        productDetailsList,
        selectedProductCategory,
        setSelectedProduct,
        setSelectedProductCategory,
    ]);

    useEffect(() => {
        if (selectedProductCategory) {
            const products = productDetailsMap.get(selectedProductCategory);
            if (products && products.length) {
                const productsData = products.map((product, index) => {
                    return {
                        key: index,
                        label: product,
                        icon: null,
                        iconColor: null,
                        selected: product === selectedProduct,
                        action: () => {
                            setSelectedProduct(product);
                        },
                    };
                });

                setProductList(productsData);
            }
        }
    }, [
        selectedProductCategory,
        selectedProduct,
        productDetailsMap,
        setSelectedProduct,
    ]);

    if (!isSmallScreen) {
        return (
            <div className='flex h-full items-center justify-between'>
                <div className='flex mx-3'>
                    <Dropdown
                        dropDownList={productCategoryList}
                        showButtonText={true}
                        buttonText={
                            selectedProductCategory
                                ? selectedProductCategory
                                : 'Select Category'
                        }
                        buttonTitle={
                            <>
                                <FontAwesomeIcon
                                    icon={faIcons}
                                    className='mr-2'
                                />
                                Product Category
                            </>
                        }
                        listTitleText={'Product Categories'}
                        buttonHoverText={'Select Product Category'}
                        buttonClassName={
                            selectedProductCategory
                                ? 'text-texts-tertiary400 rounded-l-xl'
                                : 'text-texts-secondary300 rounded-l-xl'
                        }
                        listClassName={''}
                        disabled={isLoading}
                    />
                </div>
                <div className='flex mx-3'>
                    <Dropdown
                        dropDownList={productList}
                        showButtonText={true}
                        buttonText={
                            selectedProduct ? selectedProduct : 'Select Product'
                        }
                        buttonTitle={
                            <>
                                <FontAwesomeIcon
                                    icon={faProductHunt}
                                    className='mr-2'
                                />
                                Product
                            </>
                        }
                        listTitleText={'Products'}
                        buttonHoverText={'Select Product'}
                        buttonClassName={
                            selectedProduct
                                ? 'text-texts-tertiary400 rounded-l-xl'
                                : 'text-texts-secondary300 rounded-l-xl'
                        }
                        listClassName={''}
                        disabled={isLoading || !selectedProductCategory}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div className='flex flex-col p-4 border-b'>
                    <div className='mb-3 text-texts-secondary300 font-semibold'>
                        <FontAwesomeIcon icon={faIcons} className='mr-2' />
                        <span>Product Category</span>
                    </div>
                    <Dropdown
                        dropDownList={productCategoryList}
                        showButtonText={true}
                        buttonText={
                            selectedProductCategory
                                ? selectedProductCategory
                                : 'Select Category'
                        }
                        titleText={'Product Categories'}
                        buttonHoverText={'Select Product Category'}
                        buttonClassName={
                            selectedProductCategory
                                ? 'text-texts-tertiary400 w-full'
                                : 'text-texts-secondary300 w-full'
                        }
                        listClassName={''}
                        disabled={isLoading}
                        placement={'bottom'}
                    />
                </div>
                <div className='flex flex-col p-4'>
                    <div className='mb-3 text-texts-secondary300 font-semibold'>
                        <FontAwesomeIcon
                            icon={faProductHunt}
                            className='mr-2'
                        />
                        <span>Product</span>
                    </div>
                    <Dropdown
                        dropDownList={productList}
                        showButtonText={true}
                        buttonText={
                            selectedProduct ? selectedProduct : 'Select Product'
                        }
                        titleText={'Products'}
                        buttonHoverText={'Select Product'}
                        buttonClassName={
                            selectedProduct
                                ? 'text-texts-tertiary400'
                                : 'text-texts-secondary300'
                        }
                        listClassName={''}
                        disabled={isLoading || !selectedProductCategory}
                        placement={'bottom'}
                    />
                </div>
            </>
        );
    }
};
