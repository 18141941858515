import search from 'approx-string-match';
import { DataTypes, acceptableErrorsPercentage } from './constant';

// Function to sanitize text
export const getSanitizedText = (text) => {
    if (!text) return '';
    return text
        .trim()
        .replace(/[^\w\s]/gi, ' ')
        .trim()
        .replace(/\s+/g, ' ')
        .trim();
};

// Function to get word count
export const getLineWordCount = (lineText) => {
    const modifiedText = getSanitizedText(lineText);
    const modifiedTextData = modifiedText.split(' ');
    return modifiedTextData.length;
};

// Function to create new clean highlight data with alpha numeric cases
export const createModifiedHighlightData = (
    highlightTexts,
    safeWordRange,
    markCompleteText
) => {
    if (!highlightTexts) {
        return null;
    }

    // Constructing empty object for modified highlights texts and marked data.
    // Also maintaining the precedence order here.
    const modifiedHighlightData = {};
    const totalWordsCountData = {};
    Object.keys(DataTypes).forEach((key) => {
        modifiedHighlightData[DataTypes[key]] = [];
        if (markCompleteText) {
            totalWordsCountData[DataTypes[key]] = [];
        }
    });

    Object.keys(highlightTexts).forEach((key) => {
        highlightTexts[key].forEach((txt) => {
            // Removing all special characters and extra space for better matching.
            let modifiedHighlightText = getSanitizedText(txt);

            const splitData = modifiedHighlightText.split(' ');
            const totalWordsCount = splitData.length;
            if (markCompleteText) {
                totalWordsCountData[key].push(totalWordsCount);
            }

            // Minimum range to build the start text
            const range =
                safeWordRange[key] < totalWordsCount
                    ? safeWordRange[key]
                    : totalWordsCount;

            // start text (subString of actual text limited by safeWordRange).
            let newStartText = '';

            for (let i = 0; i < range; i++) {
                if (
                    splitData[i] !== '' &&
                    splitData[i] !== undefined &&
                    splitData[i].trim()
                ) {
                    newStartText += splitData[i].trim() + ' ';
                }
            }

            if (newStartText.trim()) {
                modifiedHighlightData[key].push(newStartText.trim());
            }
        });
    });
    // console.log('MODIFIED HIGHLIGHT DATA:\n', modifiedHighlightData);

    // returning additional word count data if marking full data is required.
    if (markCompleteText) {
        return { highlightData: modifiedHighlightData, totalWordsCountData };
    }

    return modifiedHighlightData;
};

// Function to get array of words and its index per page.
export const getIndexLineData = (pageData) => {
    if (!pageData) return null;

    const indexLineData = [];
    let completeTextChunk = ''; // variable to hold complete page data if treated as whole single text chunk.
    let currentIndex = 0; // Word start index to track perticular sentence start if page data is treated as whole text chunk.

    pageData['items'].forEach((item, index) => {
        const text = item['str'];
        if (text && text.trim()) {
            const modifiedText = getSanitizedText(text);

            if (modifiedText && modifiedText.trim()) {
                const newModifiedText = modifiedText.trim() + ' '; // adding space for better indexing.
                const data = {
                    lineIndex: index,
                    startIndex: currentIndex,
                    // line: modifiedText,
                };
                indexLineData.push(data);

                completeTextChunk += newModifiedText;
                currentIndex += newModifiedText.length;
            }
        }
    });

    // console.log('\nINDEX LINE DATA:', indexLineData);
    // console.log('\nCOMPLETE TEXT CHUNK:', completeTextChunk);

    return { completeTextChunk, indexLineData };
};

// Find and return line index based on start index of a string.
const findLineIndex = (indexData, startIndex) => {
    let i = 0;
    for (i = 0; i < indexData.length - 1; i++) {
        if (startIndex === indexData[i].startIndex) {
            return indexData[i].lineIndex;
        }
        if (
            startIndex > indexData[i].startIndex &&
            startIndex < indexData[i + 1].startIndex
        ) {
            return indexData[i].lineIndex;
        }
    }
    // left over case.
    if (
        startIndex === indexData[i].startIndex ||
        startIndex > indexData[i].startIndex
    ) {
        return indexData[i].lineIndex;
    }

    return -1;
};

// Function to get start index of given text data in a page.
export const getMatchedIndexData = (textAndIndexData, highlightData) => {
    const { completeTextChunk, indexLineData } = textAndIndexData;
    const updatedIndexData = {};
    const markedIndexData = {};

    const keysData = Object.keys(highlightData);
    keysData.forEach((key) => {
        updatedIndexData[key] = [];
        markedIndexData[key] = {};

        highlightData[key].forEach((text, index) => {
            if (text && text.trim()) {
                const acceptableErrors = Math.floor(
                    (acceptableErrorsPercentage[key] * text.length) / 100
                );

                const matchedData = search(
                    completeTextChunk,
                    text,
                    acceptableErrors
                );

                if (matchedData.length) {
                    let matchDataIndex = 0;
                    while (matchDataIndex < matchedData.length) {
                        const startIndex = matchedData[matchDataIndex].start;

                        // console.log(
                        //     `\nMATCHED ${key} DATA:\n`,
                        //     matchedData,
                        //     completeTextChunk.substring(
                        //         [matchedData[matchDataIndex].start],
                        //         [matchedData[matchDataIndex].end + 1]
                        //     )
                        // );

                        const lineIndex = findLineIndex(
                            indexLineData,
                            startIndex,
                            markedIndexData
                        );

                        if (
                            lineIndex > -1 &&
                            !markedIndexData[key][lineIndex]
                        ) {
                            updatedIndexData[key][index] = {
                                startIndex: lineIndex,
                                text: text,
                            };
                            markedIndexData[key][lineIndex] = text;
                            break;
                        }

                        matchDataIndex++;
                    }
                }
            }
        });
    });

    // console.log('\nINDEX DATA:', updatedIndexData);
    // console.log('\nMARKED INDEX DATA:', markedIndexData);

    return markedIndexData;
};

// Function to find if a char is an icon.
export const isIcon = (strData) => {
    const charCount = strData.str.trim().length;
    if (charCount === 1) {
        // Assuming it is an icon
        return true;
    }
    return false;
};

// Function to check if given content is withing page range.
export const isInPageRange = (page, activeChunkPage, pageRange) => {
    if (!activeChunkPage) {
        return true;
    }

    if (page >= activeChunkPage && page <= activeChunkPage + pageRange)
        return true;

    return false;
};

// Function to mark data based on type of data.
export const renderMark = (dataType, lineText, startWord) => {
    // Finding exact start of chunk.
    const startIndex = startWord ? lineText.search(startWord) : 0;
    const preUnmarkedText = lineText.slice(0, startIndex);
    const markedText = lineText.slice(startIndex, lineText.length);

    if (dataType === DataTypes.CHAPTERS) {
        // console.log('-----CHAPTER START DETECTED-----');
        return `${preUnmarkedText}<mark class='chapters'>${markedText}</mark>`;
    } else if (dataType === DataTypes.TABLES) {
        // console.log('-----TABLE START DETECTED-----');
        return `${preUnmarkedText}<mark class='tables'>${markedText}</mark>`;
    } else if (dataType === DataTypes.CHUNKS) {
        // console.log('-----CHUNK START DETECTED-----');
        return `${preUnmarkedText}<mark class='chunks'>${markedText}</mark>`;
    }
};

// Function that highlights the pdf by modifying the text layer.
export const renderMultipleHighlights = (lineData, indexData) => {
    // console.log('LINE DATA: ', lineData);
    const lineText = lineData.str;
    const lineIndex = lineData.itemIndex;

    const keysData = Object.keys(indexData);

    for (let i = 0; i < keysData.length; i++) {
        const line = indexData[keysData[i]][lineIndex];
        const startWord = line ? line.split(' ')[0] : null;

        // console.log('LINE INDEX:', lineIndex);
        // console.log('LINE TEXT:', lineText);
        // console.log('START WORD:', startWord);
        // console.log('-------------------------------------');

        if (startWord) {
            return renderMark(keysData[i], lineText, startWord);
        }
    }

    return lineText;
};
