import { Bounce, toast } from 'react-toastify';

export const NotificationType = Object.freeze({
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
});

const configuration = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition: Bounce,
};

export const notify = (notificationText, type) => {
    if (type === NotificationType.SUCCESS) {
        toast.success(notificationText, configuration);
    } else if (type === NotificationType.INFO) {
        toast.info(notificationText, configuration);
    } else if (type === NotificationType.WARNING) {
        toast.warn(notificationText, configuration);
    } else if (type === NotificationType.ERROR) {
        toast.error(notificationText, configuration);
    } else toast(notificationText, configuration);
};
