import React from 'react';
import Lottie from 'react-lottie';
import notFoundAnimation from '../lotties/notFoundAnimation.json';

const options = {
    loop: true,
    autoplay: true,
    animationData: notFoundAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const size = 200;

function NotFoundComponent() {
    return (
        <div className='w-full flex items-center justify-center min-h-screen bg-gradient-primary select-none p-6'>
            <div className='w-full max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                <div className='flex flex-col w-full justify-center items-center text-texts-secondary300 p-4'>
                    <Lottie options={options} height={size} width={size} />
                    <div className='text-lg font-semibold mb-3'>
                        Oops! Page not found
                    </div>
                    <div className='text-base text-center'>
                        Please make sure you are on right link
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundComponent;
